import { createDuck } from '../../lib/fw/store/createDuck';
import { arrayToMap } from '../../lib/utils/list';

const initialState = {
  clientsMap: {},
};

const [
  actions,
  reducer,
] = createDuck('CLIENT', initialState, {
  setClients: (state, clients) => ({
    ...state,
    clientsMap: arrayToMap(clients),
  }),

  setClient: (state, client) => ({
    ...state,
    clientsMap: {
      ...state.clientsMap,
      [client.id]: client,
    },
  }),

  removeClient: (state, clientId) => {
    const newState = {
      ...state,
      clientsMap: { ...state.clientsMap },
    };
    if (newState.clientsMap[clientId]) {
      delete newState.clientsMap[clientId];
    }

    return newState;
  },
});


export const {
  setClients: setClientsAction,
  setClient: setClientAction,
  removeClient: removeClientAction,
} = actions;

export default reducer;
