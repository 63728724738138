function setWith(object, paths, value) {
  let obj = object;
  paths.forEach((path, i) => {
    if (i === paths.length - 1) {
      obj[path] = value;
    } else {
      if (!(path in obj)) {
        obj[path] = {};
      }
      obj = obj[path];
    }
  });
}


function createDuck(prefix, _initialState, actions) {
  const handlers = {};
  const actionCreators = {};

  function addActionHandler(parents, actionName, actionReducer) {
    const action = `${prefix}/${[...parents, actionName].join('/')}`;

    const actionCreator = (...args) => ({
      type: action,
      payload: args,
    });

    actionCreator.toString = () => action;
    setWith(actionCreators, [...parents, actionName], actionCreator);

    if (actionReducer) {
      handlers[action] = (state, action) => actionReducer(state, ...action.payload);
    }
  }

  function addActionsHandler(parents, subActions) {
    Reflect.ownKeys(subActions).forEach((actionName) => {
      if (typeof subActions[actionName] === 'function') {
        addActionHandler(parents, actionName, subActions[actionName]);
      } else if (typeof subActions[actionName] === 'object') {
        addActionsHandler([...parents, actionName], subActions[actionName]);
      }
    });
  }

  addActionsHandler([], actions);

  const reducer = (state = _initialState, action) => {
    const handler = handlers[action.type];
    return handler ? handler(state, action) : state;
  };

  return [actionCreators, reducer];
}


function createApiDuck(prefix, api) {
  const initialState = {
    loading: false,
    errors: null,
    response: null,
  };

  return createDuck(prefix, initialState, {
    [api]: {
      request: (state) => ({
        ...initialState,
        loading: true,
      }),
      success: (state, response) => ({
        ...initialState,
        response,
      }),
      failure: (state, errors) => ({
        ...initialState,
        errors,
      }),
      reset: () => initialState,
    },
  });
}

export { createDuck, createApiDuck };

