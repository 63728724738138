export function arrayToMap(arr) {
  return arr.reduce((map, item) => {
    map[item.id] = item;
    return map;
  }, {});
}

export function mapToList(map) {
  return Reflect.ownKeys(map).map((k) => map[k]);
}
