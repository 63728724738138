import React from 'react';
import * as PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import nextWithRedux from '../src/hoc/nextWithRedux';

import '../styles/index.css';

import '@blueprintjs/core/lib/css/blueprint.css';
import '../src/components/blueprint';

function MainApp({ Component, pageProps, reduxStore }) {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <Provider store={reduxStore}>
      {getLayout(<Component {...pageProps} />)}
    </Provider>
  );
}

MainApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
  reduxStore: PropTypes.object,
};

export default nextWithRedux(MainApp);
